<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-between">
              <div>
                <h3>
                  Edite o usuário
                </h3>
              </div>
              <div>
                <b-form-checkbox
                  v-model="changePassword"
                >
                  <p><strong>Alterar senha do usuário</strong></p>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
            <b-form-group

              label="Nome"
              label-for="Nome"
            >
              <b-form-input
                id="Nome"
                v-model="nome"
                placeholder="Nome do Usuário"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="E-mail *"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="E-mail"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group

              label="Username *"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="username"
                placeholder="Username"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Permissão *"
              label-for="permissao"
            >
              <v-select
                id="permissao"
                v-model="permissao"
                label="name"
                placeholder="Selecione uma Permissão"
                :options="permissoes"
              >
                <template #option="{ name, icon }">
                  <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="changePassword"
            md="4"
          >
            <b-form-group
              label="Senha *"
              label-for="password"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  v-model="password"
                  placeholder="Senha"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="changePassword"
            md="4"
          >
            <b-form-group
              label="Confirme a senha *"
              label-for="confirmPassword"
            >
              <b-input-group>
                <b-form-input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  placeholder="Confirme a senha"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="SalvarUsuario()"
            >
              <feather-icon
                icon="CheckIcon"
              />
              Adicionar Usuário
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>

</template>
<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'Addusuarios',
  mixins: [togglePasswordVisibility],
  data() {
    return {
      id: '',
      nome: '',
      username: '',
      email: '',
      permissao: '',
      permissoes: [],
      changePassword: false,
      password: '',
      confirmPassword: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getData()
    this.$http.get('user/roles').then(response => {
      this.permissoes = response.data
    })
  },
  methods: {
    getData() {
      this.id = this.$route.params.dados.id
      this.nome = this.$route.params.dados.name
      this.username = this.$route.params.dados.username
      this.email = this.$route.params.dados.email
      this.$route.params.dados.role_user.forEach(item => {
        this.permissao = item.role
      })
    },
    async SalvarUsuario() {
      if (this.nome.length < 1) return this.$toast.error('Preencha o nome!')
      if (this.email.length < 1) return this.$toast.error('Preencha o email!')
      if (this.username.length < 1) return this.$toast.error('Preencha o username!')
      if (this.permissao.length < 1) return this.$toast.error('Preencha a permissão!')
      if (this.changePassword) {
        if (this.password.length < 1) return this.$toast.error('Preencha as senha!')
        if (this.confirmPassword.length < 1) return this.$toast.error('Preencha as senha!')
        if (this.password !== this.confirmPassword) return this.$toast.error('Senhas não conferem!')
      }
      const obj = {
        id: this.id,
        name: this.nome,
        username: this.username,
        email: this.email,
        role: this.permissao.slug,
        changePassword: this.changePassword,
        password: this.password,
      }
      this.$loading(true)
      await this.$http.post('user/edit', obj).then(resp => {
        this.$loading(false)
        if (resp.data.success) {
          this.$toast.success('Usuario Cadastrado com sucesso')
          this.$router.push('/usuarios')
        } else {
          this.$toast.error('Erro ao cadastrar o usuario.')
        }
      })
      return true
    },
  },
}
</script>

<style scoped>

</style>
